.front_jumbo {
	.container {
		padding: 0 30px 30px;
	}
	.wrap {
		padding: 100px 0;
		background: url("/_/images/layout/jumbo-bg-1.jpg") center center no-repeat;
		background-size: cover;
	}
	@include media-breakpoint-up(md) {
		.container { padding: 0 45px 30px; }
		.wrap { padding: 150px 0; }
	}
	@include media-breakpoint-up(lg) {
		.wrap { padding: 180px 0; }
	}
	@include media-breakpoint-up(xl) {
		.wrap { padding: 210px 0; }
	}
}