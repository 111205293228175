.featured_wrapper {
	@include media-breakpoint-down(md) {
		@include make-flex;
		flex-wrap: wrap;
		justify-content: center;
		> div {
			width: 100%;
		}
	}
	@include media-breakpoint-only(md) {
		> div {
			width: 49%;
			&:nth-child(even) { margin-left: auto; }
		}
	}
}


.abox_wrapper {
	@include make-flex;
	justify-content: space-evenly;
	padding: 0;
	@include media-breakpoint-down(sm) {
		.abox { width: 48%; }
	}
	@include media-breakpoint-only(md) {
		.abox { width: 32%; }
	}
	.page_front & {
		@include media-breakpoint-only(md) {
			.abox:last-child { display: none; }
		}
		@include media-breakpoint-up(lg) {
			.abox { width: 48%; }
		}
	}
}


.abox {
	margin: 0 0 2%;
	padding: 15px;
	@include font-size(14);
	line-height: 1em;
	text-align: center;
	border: 1px solid darken($body-bg, 7.5%);
	background-color: $white;
	img {
		display: block;
		width: auto;
		max-height: 100px;
		margin: 0 auto;
	}
	.abox-title {
		display: block;
		//margin: 10px 0 5px;
		margin: 5px 0;
		//padding-top: 10px;
		@include font-size(16);
		line-height: 1em;
		letter-spacing: -0.0275em;
		//border-top: 1px dotted #ccc;
		small { display: block; }
	}
	.phone {
		@include font-size(17);
		line-height: 1em;
		font-weight: $body-weight-bolder;
		letter-spacing: -0.0425em;
		color: theme(secondary, base) !important;
	}
	p {
		margin-top: 10px;
		line-height: 1.275em;
	}
	> *:last-child {
		margin-bottom: 0 !important;
	}

}

.afeat_wrapper {
	margin-bottom: 20px;
	> .afeat:not(:last-child) { margin-bottom: 20px; }
}

.afeat {
	position: relative;
	text-align: center;
	line-height: 1.1;
	letter-spacing: -0.0325em;
	border: 2px solid #143F61;
	background-color: white;
	> *:first-child { margin-top: 0; }
	> *:last-child { margin-bottom: 0; }

	.btn {
		@include btn-solid(#143F61);
	}

	@include media-breakpoint-only(md) {
		@include make-flex;
		flex-wrap: nowrap;
		align-items: center;
		.afeat-p { min-width: 280px; max-width: 280px; }
	}

}

	.afeat-details {
		position: relative;
		padding: 10px 15px 15px;
		background-color: rgba(white, 0.88);
		@include media-breakpoint-down(sm) {
			position: absolute;
			bottom: 0;
			width: 100%;
		}
		@include media-breakpoint-up(lg) {
			z-index: 5;
			margin-top: -88px;
			padding-top: 0;
		}
	}

	.afeat-logo {
		display: block;
		width: 100%;
		max-width: 300px;
		margin: 0 auto 15px;
	}

	.afeat-products { z-index: 1; }

	.afeat-p {
		display: block;
		width: 100%;
	}