body {

	min-width: 360px;
	padding-top: 30px;
	background-color: $body-bg;
	background-image: 	url("/_/images/layout/body.png"),
						linear-gradient(to bottom, theme(primary, dark), theme(primary, light));
	
	&:not(.page_front) {
			
		@include media-breakpoint-up(md) {

			height: 100%;
			padding-bottom: 48px;

			.site_info {
				position: absolute;
				bottom: 0;
				width: 100%;
			}

		}
		
	}


}
